import React, { useEffect } from "react";
import { useForm } from "Hooks/validator";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Input from "components/Input";
import { Button } from "@material-ui/core";
import trilliumLogo from "../../assets/Trillium-health-logo.png";
// import { useHistory } from "react-router-dom";
import { login } from "Redux/Login/login.actions";
import { getRoundingCache } from "Redux/Rounding/rounding.actions";
import { useState } from "react";
import "./style.css";

/**
 *Main function of the page page
 * @param props can be used if values needs to be passed to this component,
 * LoginData prop contains the login api response data
 * @returns {JSX.Element}
 */

function Login(props) {
  // const history = useHistory();
  const [loginError, setloginError] = useState("");
  const [userId, setUserId] = useState("");

  const defaultValues = {
    clinicId: null,
    userName: "",
    password: "",
  };

  const customErrorAttribute = {
    error: true,
  };

  const { values, setValues, useInput, isValid } = useForm(
    defaultValues,
    customErrorAttribute
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      localStorage.setItem("clinic_id", values.clinicId);
      props.login({
        clinicId: values.clinicId,
        userName: values.userName,
        password: values.password,
      });
    }
  };

  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }, []);

  useEffect(() => {
    if (
      props.loginData &&
      props.loginData.loginResponse &&
      props.loginData.loginResponse.response
    ) {
      if (props.loginData.loginResponse.response.responseCode === 0) {
        let data = props.loginData.loginResponse.response.data;
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("clinicId", data.clinicId);
        localStorage.setItem("userId", data.userId);
        // console.log(data);
        props.getRoundingCache(data.userId);
        setUserId(data.userId);
      }
    }
  }, [props.loginData.loginResponse.response]);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.roundingCacheRes &&
      props.roundingData.roundingCacheRes.response
    ) {
      if (props.roundingData.roundingCacheRes.response.responseCode === 0) {
        let data = props.roundingData.roundingCacheRes.response.data;
        console.log(data);
        let providers = data.memoryCash.provider;
        let provider = providers.filter((x) => x.userId === userId);
        let providerId = provider[0].id;
        localStorage.setItem("roundingCache", JSON.stringify(data.memoryCash));
        localStorage.setItem("providerId", providerId);
        localStorage.setItem("clinicName", data.loginInfo.clinicName);
        window.location.href = "/images";
      }
    }
  }, [props.roundingData.roundingCacheRes.response]);

  return (
    <div className="clinicLogin scrollbarY--custom">
      <header className="trillium-health-logo-header">
        <div className="trillium-health-logo-container">
          <a href="https://trillium.health/">
            <img
              src={trilliumLogo}
              alt="trillium-health-logo"
              className="trillium-health-logo"
            />
          </a>
        </div>
      </header>

      <main className="clinicLogin__main">
        <section className="clinicLogin__section">
          <div>
            <h1 className="clinicLogin__grid__heading">
              Physician Application
            </h1>
          </div>

          <div>
            <form onSubmit={handleSubmit} className="clinicLogin__form">
              <div className="clinicLogin__inputField">
                <Input
                  margin="normal"
                  autoFocus
                  // type="text"
                  label="Clinic ID"
                  variant="outlined"
                  className="login_input"
                  error={loginError ? true : false}
                  {...useInput("clinicId", {
                    isRequired: {
                      value: true,
                      message: "Enter a valid ID",
                    },
                  })}
                />

                <Input
                  margin="normal"
                  type="text"
                  label="Username"
                  variant="outlined"
                  className="login_input"
                  error={loginError ? true : false}
                  {...useInput("userName", {
                    isRequired: {
                      value: true,
                      message: "Enter a valid Username",
                    },
                  })}
                />

                <Input
                  margin="normal"
                  required
                  type="password"
                  label="Password"
                  variant="outlined"
                  className="login_input"
                  error={loginError ? true : false}
                  autoComplete="current-password"
                  helperText={loginError}
                  {...useInput("password", {
                    isRequired: {
                      value: true,
                      message: "Enter a valid Password",
                    },
                  })}
                />

                <div className="btnWrapper">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    // btname="Login"
                    className="clinicLogin__submitBtn"
                    // onClick={redirect}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </form>

            <div className="footer_container"></div>
          </div>
        </section>
      </main>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginData: state.login,
  roundingData: state.rounding,
});

const mapDispatchToProps = (dispatch) => ({
  login: (values) => dispatch(login(values)),
  getRoundingCache: (values) => dispatch(getRoundingCache(values)),
});

Login.propTypes = {
  loginData: PropTypes.object,
  roundingData: PropTypes.object,
  login: PropTypes.func,
  getRoundingCache: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
