import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dropdown from "components/Dropdown";
import dischargeIcon from "../../assets/icons/discharged_flag_icon.svg";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import CloseIcon from "@material-ui/icons/Close";
import { EditRounded } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Datepicker from "components/DatePicker";
import {
  getRoundingList,
  addRounding,
  editRounding,
  getRoundingDetails,
  saveRoundingDetails,
  searchPatient,
  savePatient,
  deletePatient,
  dischargePatient,
  searchIcd,
  saveIcd,
  searchCpt,
  saveCpt,
  favouriteIcdList,
  favouriteCptList,
  saveFavouriteIcd,
  deleteFavouriteIcd,
  saveFavouriteCpt,
  deleteFavouriteCpt,
} from "Redux/Rounding/rounding.actions";
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  Grid,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import PhoneFormat from "components/PhoneFormat";
import { states } from "../../HelperFunctions/states";
import toast from "react-hot-toast";
import FavIcdDialog from "components/Rounding/FavouriteIcd";
import FavCptDialog from "components/Rounding/FavouriteCpt";
import "./style.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const dateFormat = (val) => {
  let date = new Date(val);
  let dt = date.getDate();
  let day;
  if (dt < 10) {
    day = "0" + dt;
  } else {
    day = dt;
  }
  let month = date.getMonth() + 1;
  let m;
  if (month < 10) {
    m = "0" + month;
  } else {
    m = month;
  }
  let year = date.getFullYear();
  return year + "-" + m + "-" + day + " " + "00:00:00";
};

function Rounding(props) {
  const [rows, setRows] = useState([]);
  let providerId = parseInt(localStorage.getItem("providerId"));
  let clinicId = parseInt(localStorage.getItem("clinicId"));
  let userId = parseInt(localStorage.getItem("userId"));
  const [selectedRounding, setSelectedRounding] = useState("");
  const [locations, setLocations] = useState([]);
  const [providers, setProviders] = useState([]);
  const [status, setStatus] = useState(1);
  const [ip, setIp] = useState(true);
  const [addPatient, setAddPatient] = useState(false);
  const [cptTab, setCptTab] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCharges, setOpenCharges] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [openDischarge, setOpenDischarge] = useState(false);
  const [openAddRounding, setOpenAddRounding] = useState(false);
  const [openAddCpt, setOpenAddCpt] = useState(false);
  const [editCpt, setEditCpt] = useState(false);
  const [dischargeDetails, setDischargeDetails] = useState({
    userId: userId,
    roundingId: "",
    dispositionLocation: "Home",
    dispositionType: "1",
  });
  const [openBillingNotes, setOpenBillingNotes] = useState(false);
  const [billerNote, setBillerNote] = useState("");
  const [roundingNote, setRoundingNote] = useState("");
  const [details, setDetails] = useState({});
  const [visitDetails, setVisitDetails] = useState({});
  const [patientDto, setPatientDto] = useState({});
  const [cptList, setCptList] = useState([]);
  const [searchCptList, setSearchCptList] = useState([]);
  const defaultCptValues = {
    cashFee: 0,
    clinicId: clinicId?clinicId:0,
    comments: "",
    cptCode: "",
    cptId: 0,
    description: "",
    favorite: true,
    favoriteCptId: 0,
    fee: 0,
    frequencyType: 0,
    modifier: "",
    ndcCode: "",
    ndcMeasure: "",
    ndcUnits: 1,
    pos: "",
    tos: "",
    unit: 0,
  };
  const [newCptValues, setNewCptValues] = useState(defaultCptValues);
  const [icdList, setIcdList] = useState({
    dx1: { icdCode: "" },
    dx2: { icdCode: "" },
    dx3: { icdCode: "" },
    dx4: { icdCode: "" },
  });
  const [dx1, setDx1] = useState({ title: "" });
  const [dx2, setDx2] = useState({ title: "" });
  const [dx3, setDx3] = useState({ title: "" });
  const [dx4, setDx4] = useState({ title: "" });
  const [dx5, setDx5] = useState({ title: "" });
  const [dx6, setDx6] = useState({ title: "" });
  const [dx7, setDx7] = useState({ title: "" });
  const [dx8, setDx8] = useState({ title: "" });
  const [openIcd, setopenIcd] = useState({ status: false, key: "" });
  const [icdForm, setIcdForm] = useState({
    icdCode: "",
    icdDescLong: "",
  });
  const [icdSearchList, setIcdSearchList] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [patientName, setPatientName] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("");
  const defaultPatientInfo = {
    active: 0,
    addressLine1: "",
    addressLine2: "",
    altPhone: "",
    balanceWriteoff: 0,
    billingMethod: 0,
    city: "",
    clinicId: clinicId?clinicId:0,
    clinicName: "",
    coins: "",
    copay: "",
    country: "",
    deductable: 0,
    dob: null,
    dos: "",
    email: "",
    emergencyContact: "",
    emergencyPhone: "",
    firstName: "",
    id: 0,
    insuranceBalance: 0,
    lastName: "",
    locationId: 0,
    locationName: "",
    maritalStatus: 0,
    masterPatientId: 0,
    middleName: "",
    mrn: "",
    notes: "",
    patientBalance: 0,
    patientOptout: 0,
    payorName: "",
    phone: "",
    pinNo: "",
    policyType: 0,
    providerId: 0,
    providerName: "",
    sdob: "",
    sex: 0,
    ssn: "",
    state: "",
    userId: 0,
    userName: "",
    zip: "",
  };
  const [patientInfo, setPatientInfo] = useState(defaultPatientInfo);
  const defaultRounding = {
    bed: "",
    providerId: "",
    notes: "",
    roundingId: 0,
    locationId: "",
    isMedicare: true,
    clinicId: clinicId,
    startDate: null,
    patientId: 0,
  };
  const [roundingValues, setRoundingValues] = useState(defaultRounding);

  const [editRoundingValues, setEditRoundingValues] = useState(defaultRounding);

  const [headCells, setHeadCells] = useState([
    {
      id: "mrn",
      label: "MRN",
    },
    {
      id: "patient",
      label: "Patient",
    },
    {
      id: "dob",
      label: "DOB",
    },
    {
      id: "facility",
      label: "Facility",
    },
    {
      id: "bed",
      label: "Bed",
    },
    {
      id: "los",
      label: "LOS",
    },
    {
      id: "flag",
      label: "Flag",
    },
    {
      id: "dos",
      label: "DOS",
    },
  ]);

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    addressLine1: "",
    city: "",
    zip: "",
  });

  const [selectedState, setSelectedState] = useState({
    name: "",
    value: "",
  });

  const [openFavIcd, setOpenFavIcd] = useState(false);
  const [openFavCpt, setOpenFavCpt] = useState(false);

  const formatDate = (val) => {
    let d = val.split("-");
    return `${d[1]}/${d[2]}/${d[0]}`;
  };

  const handleAddIcon = () => {
    setOpenAddRounding(true);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleCptTab = (event, newValue) => {
    setCptTab(newValue);
  };

  useEffect(() => {
    props.getRoundingList(providerId);
    let cache = JSON.parse(localStorage.getItem("roundingCache"));
    setProviders(
      cache.provider.map((x) => {
        return { name: x.fullName, value: x.id };
      })
    );
    setLocations(
      cache.locations.map((x) => {
        return { name: x.locationName, value: x.id };
      })
    );
  }, []);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.roundingListRes &&
      props.roundingData.roundingListRes.response
    ) {
      if (props.roundingData.roundingListRes.response.responseCode === 0) {
        let data = props.roundingData.roundingListRes.response.data;
        setRows(data);
        props.getRoundingDetails({
          roundingId: data[0].roundingId,
          dos: data[0].startDate,
        });
      } else {
        toast.error("Unexpected error. Try again later.");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.roundingListRes]);

  const handleAddRounding = () => {
    props.addRounding({
      ...roundingValues,
      startDate: dateFormat(roundingValues.startDate),
    });
  };

  const handleEditRounding = () => {
    props.editRounding({
      ...editRoundingValues,
      startDate: dateFormat(editRoundingValues.startDate),
    });
  };

  const fillData = (x) => {
    let p = patientList.filter((item) => item.id === x.id)[0];
    setPatientName(p.firstName + " " + p.lastName);
    setRoundingValues({
      ...roundingValues,
      patientId: p.id,
    });
  };

  const handleSearchPatient = () => {
    props.searchPatient({
      firstName: patientInfo.firstName,
      lastName: patientInfo.lastName,
      // dob: patientInfo.dob ? dateFormat(patientInfo.dob) : "",
      clinicId: clinicId,
    });
  };

  const handleNewPatient = () => {
    setAddPatient(true);
  };

  const handleSavePatient = () => {
    props.savePatient(patientInfo);
  };

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.searchPatientResponse &&
      props.roundingData.searchPatientResponse.response
    ) {
      if (
        props.roundingData.searchPatientResponse.response.responseCode === 0
      ) {
        let data = props.roundingData.searchPatientResponse.response.data;
        if (data.length) {
          setShowTable(true);
          setPatientList(data);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.searchPatientResponse.response]);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.savePatientResponse &&
      props.roundingData.savePatientResponse.response
    ) {
      if (props.roundingData.savePatientResponse.response.responseCode === 0) {
        toast.success("Patient added successfully");
        setAddPatient(false);
        let data = props.roundingData.savePatientResponse.response.data;
        if (data.id) {
          let id = data.id;
          setRoundingValues({
            ...roundingValues,
            patientId: id,
          });
          setPatientName(patientInfo.firstName + " " + patientInfo.lastName);
          // let p = patientList.filter((item) => item.id === id)[0];
          // setPatientName(p.firstName + " " + p.lastName);
        }
        setPatientInfo(defaultPatientInfo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.savePatientResponse.response]);

  const handleRowSelect = (row) => {
    props.getRoundingDetails({
      roundingId: row.roundingId,
      dos: row.startDate,
    });
    setSelectedRounding(row.roundingId);
  };

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.roundingDetailsRes &&
      props.roundingData.roundingDetailsRes.response
    ) {
      if (props.roundingData.roundingDetailsRes.response.responseCode === 0) {
        let data = props.roundingData.roundingDetailsRes.response.data;
        setDetails(data.roundingDetails);
        setSelectedRounding(data.roundingDetails.roundingId);
        setVisitDetails(data.visitChargeDto);
        setPatientDto(
          data.visitChargeDto ? data.visitChargeDto.patientDto : {}
        );
        setIcdList(
          data.visitChargeDto
            ? data.visitChargeDto.visitDiagnosiDto
            : {
                dx1: { icdCode: "" },
                dx2: { icdCode: "" },
                dx3: { icdCode: "" },
                dx4: { icdCode: "" },
              }
        );
        if (data.visitChargeDto) {
          let info = data.visitChargeDto.visitDiagnosiDto;
          setDx1({
            clinicId: info.dx1.clinicId,
            title: info.dx1.icdCode,
            icdCode: info.dx1.icdCode,
            icdId: info.dx1.icdId,
            icdDescLong: info.dx1.icdDescLong,
            favourite: info.dx1.favourite,
            icdDescShort: info.dx1.icdDescShort,
            rangeId: info.dx1.rangeId,
          });
          setDx2({
            clinicId: info.dx2.clinicId,
            title: info.dx2.icdCode,
            icdCode: info.dx2.icdCode,
            icdId: info.dx2.icdId,
            icdDescLong: info.dx2.icdDescLong,
            favourite: info.dx2.favourite,
            icdDescShort: info.dx2.icdDescShort,
            rangeId: info.dx2.rangeId,
          });
          setDx3({
            clinicId: info.dx3.clinicId,
            title: info.dx3.icdCode,
            icdCode: info.dx3.icdCode,
            icdId: info.dx3.icdId,
            icdDescLong: info.dx3.icdDescLong,
            favourite: info.dx3.favourite,
            icdDescShort: info.dx3.icdDescShort,
            rangeId: info.dx3.rangeId,
          });
          setDx4({
            clinicId: info.dx4.clinicId,
            title: info.dx4.icdCode,
            icdCode: info.dx4.icdCode,
            icdId: info.dx4.icdId,
            icdDescLong: info.dx4.icdDescLong,
            favourite: info.dx4.favourite,
            icdDescShort: info.dx4.icdDescShort,
            rangeId: info.dx4.rangeId,
          });
          setDx5({ icdCode: "" });
          setDx6({ icdCode: "" });
          setDx7({ icdCode: "" });
          setDx8({ icdCode: "" });
        }
        setCptList(
          data.visitChargeDto
            ? data.visitChargeDto.visitServiceDtoList.map((item) => ({
                ...item,
                favoriteCptId: 0,
                dosFrom: data.visitChargeDto.dos,
                parentTab: 1,
                dosTo: data.visitChargeDto.dos,
                modifier: "",
              }))
            : []
        );
        let r = data.roundingDetails;
        setEditRoundingValues({
          ...editRoundingValues,
          bed: r.patientBed,
          providerId: r.providerId,
          refProviderId: r.refProviderId,
          refProviderName: "",
          notes: r.notes,
          roundingId: r.roundingId,
          locationId: r.locationId,
          isMedicare: r.isMedicare,
          startDate: new Date(r.startDate),
          patientId: r.patientId,
        });
        setSelectedPatient(r.patientFirstName + " " + r.patientLastName);
      } else {
        toast.error("Unexpected error. Try again later.");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.roundingDetailsRes]);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.addRoundingRes &&
      props.roundingData.addRoundingRes.response
    ) {
      if (props.roundingData.addRoundingRes.response.responseCode === 0) {
        toast.success("New rounding added");
        setRoundingValues(defaultRounding);
        setPatientInfo(defaultPatientInfo);
        setPatientList([]);
        props.getRoundingList(providerId);
        setOpenAddRounding(false);
      } else if (
        props.roundingData.addRoundingRes.response.responseCode === 132
      ) {
        toast.error("Patient already exists in rounding");
        setOpenAddRounding(false);
        setPatientInfo(defaultPatientInfo);
        setPatientList([]);
      } else {
        toast.error("Unable to add rounding");
        setOpenAddRounding(false);
        setRoundingValues(defaultRounding);
        setPatientInfo(defaultPatientInfo);
        setPatientList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.addRoundingRes.response]);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.editRoundingRes &&
      props.roundingData.editRoundingRes.response
    ) {
      if (props.roundingData.editRoundingRes.response.responseCode === 0) {
        toast.success("Rounding updated successfully");
        setEditRoundingValues(defaultRounding);
        setPatientInfo(defaultPatientInfo);
        setPatientList([]);
        props.getRoundingList(providerId);
        setOpenEdit(false);
      } else {
        toast.error("Unable to update rounding");
        setOpenEdit(false);
        setEditRoundingValues(defaultRounding);
        setPatientInfo(defaultPatientInfo);
        setPatientList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.editRoundingRes.response]);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.deletePatientRes &&
      props.roundingData.deletePatientRes.response
    ) {
      if (props.roundingData.deletePatientRes.response.responseCode === 0) {
        toast.success("Rounding deleted successfully");
        props.getRoundingList(providerId);
        setOpenRemove(false);
      } else {
        toast.error("Unable to delete patient");
        setOpenRemove(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.deletePatientRes.response]);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.dischargePatientRes &&
      props.roundingData.dischargePatientRes.response
    ) {
      if (props.roundingData.dischargePatientRes.response.responseCode === 0) {
        toast.success("Patient discharged successfully");
        props.getRoundingList(providerId);
        setOpenDischarge(false);
      } else {
        toast.error("Unable to discharge patient");
        setOpenDischarge(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.dischargePatientRes.response]);

  const validateFirstName = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, firstName: "Enter a valid Name" });
    } else {
      setErrors({ ...errors, firstName: "" });
    }
  };

  const validateLastName = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, lastName: "Enter a valid Name" });
    } else {
      setErrors({ ...errors, lastName: "" });
    }
  };

  const validateCity = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, city: "Enter valid City" });
    } else {
      setErrors({ ...errors, city: "" });
    }
  };

  const validateAddress1 = (value) => {
    if (!/^[a-zA-Z-0-9 -'#&@,]+$/.test(value)) {
      setErrors({ ...errors, addressLine1: "Enter a valid Address" });
    } else {
      setErrors({ ...errors, addressLine1: "" });
    }
  };

  const validateZip = (value) => {
    if (!/^[0-9]{5}(?:-[0-9]{4})?$/.test(value)) {
      setErrors({ ...errors, zip: "Enter valid Zip" });
    } else {
      setErrors({ ...errors, zip: "" });
    }
  };

  const handleIcd = (param, x) => {
    let data = x ? x : "";
    setIcdSearchList([]);
    if (param === "dx1") {
      setDx1({
        clinicId: data.clinicId,
        title: data.icdCode,
        icdId: data.icdId,
        icdCode: data.icdCode,
        icdDescShort: data.icdDescShort,
        icdDescLong: data.icdDescLong,
        editable: data.editable,
        rangeId: data.rangeId,
      });
    }
    if (param === "dx2") {
      setDx2({
        clinicId: data.clinicId,
        title: data.icdCode,
        icdId: data.icdId,
        icdCode: data.icdCode,
        icdDescShort: data.icdDescShort,
        icdDescLong: data.icdDescLong,
        editable: data.editable,
        rangeId: data.rangeId,
      });
    }
    if (param === "dx3") {
      setDx3({
        clinicId: data.clinicId,
        title: data.icdCode,
        icdId: data.icdId,
        icdCode: data.icdCode,
        icdDescShort: data.icdDescShort,
        icdDescLong: data.icdDescLong,
        editable: data.editable,
        rangeId: data.rangeId,
      });
    }
    if (param === "dx4") {
      setDx4({
        clinicId: data.clinicId,
        title: data.icdCode,
        icdId: data.icdId,
        icdCode: data.icdCode,
        icdDescShort: data.icdDescShort,
        icdDescLong: data.icdDescLong,
        editable: data.editable,
        rangeId: data.rangeId,
      });
    }
    if (param === "dx5") {
      setDx5({
        title: data.icdCode,
        icdId: data.icdId,
        icdCode: data.icdCode,
        icdDescShort: data.icdDescShort,
        icdDescLong: data.icdDescLong,
        editable: data.editable,
        rangeId: data.rangeId,
      });
    }
    if (param === "dx6") {
      setDx6({
        title: data.icdCode,
        icdId: data.icdId,
        icdCode: data.icdCode,
        icdDescShort: data.icdDescShort,
        icdDescLong: data.icdDescLong,
        editable: data.editable,
        rangeId: data.rangeId,
      });
    }
    if (param === "dx7") {
      setDx7({
        title: data.icdCode,
        icdId: data.icdId,
        icdCode: data.icdCode,
        icdDescShort: data.icdDescShort,
        icdDescLong: data.icdDescLong,
        editable: data.editable,
        rangeId: data.rangeId,
      });
    }
    if (param === "dx8") {
      setDx8({
        title: data.icdCode,
        icdId: data.icdId,
        icdCode: data.icdCode,
        icdDescShort: data.icdDescShort,
        icdDescLong: data.icdDescLong,
        editable: data.editable,
        rangeId: data.rangeId,
      });
    }
  };

  const handleSaveRoundingDetails = () => {
    props.saveRoundingDetails({
      providerId: details.providerId,
      visitId: visitDetails.visitId,
      visitDiagnosiDto: {
        visitId: visitDetails.visitId,
        visitDiagnosisId: icdList.visitDiagnosisId,
        patientId: details.patientId,
        clinicId: details.clinicId,
        dx1: {
          clinicId: dx1.clinicId,
          icdDescShort: dx1.icdDescShort,
          icdId: dx1.icdId,
          favourite: dx1.favourite,
          icdDescLong: dx1.icdDescLong,
          icdCode: dx1.icdCode,
          rangeId: dx1.rangeId,
        },
        dx2: {
          clinicId: dx2.clinicId,
          icdDescShort: dx2.icdDescShort,
          icdId: dx2.icdId,
          favourite: dx2.favourite,
          icdDescLong: dx2.icdDescLong,
          icdCode: dx2.icdCode,
          rangeId: dx2.rangeId,
        },
        dx3: {
          clinicId: dx3.clinicId,
          icdDescShort: dx3.icdDescShort,
          icdId: dx3.icdId,
          favourite: dx3.favourite,
          icdDescLong: dx3.icdDescLong,
          icdCode: dx3.icdCode,
          rangeId: dx3.rangeId,
        },
        dx4: {
          clinicId: dx4.clinicId,
          icdId: dx4.icdId,
          icdDescShort: dx4.icdDescShort,
          favourite: dx4.favourite,
          icdDescLong: dx4.icdDescLong,
          icdCode: dx4.icdCode,
          rangeId: dx4.rangeId,
        },
        dx5: {
          clinicId: "",
          icdDescShort: "",
          icdId: "",
          favourite: "",
          icdDescLong: "",
          icdCode: "",
          rangeId: "",
        },
        dx6: {
          clinicId: "",
          icdDescShort: "",
          icdId: "",
          favourite: "",
          icdDescLong: "",
          icdCode: "",
          rangeId: "",
        },
        dx7: {
          clinicId: "",
          icdDescShort: "",
          icdId: "",
          favourite: "",
          icdDescLong: "",
          icdCode: "",
          rangeId: "",
        },
        dx8: {
          clinicId: "",
          icdDescShort: "",
          icdId: "",
          favourite: "",
          icdDescLong: "",
          icdCode: "",
          rangeId: "",
        },
      },
      dos: visitDetails.dos,
      roundingId: details.roundingId,
      clinicId: visitDetails.clinicId,
      appointmentId: 0,
      status: 1,
      locationId: details.locationId,
      inOutPatientStatus: 1,
      providerName: details.providerName,
      patientId: details.patientId,
      id: 0,
      isDelete: false,
      visitServiceDtoList: cptList,
      note: "",
    });
  };

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.saveRoundingDetailsRes &&
      props.roundingData.saveRoundingDetailsRes.response
    ) {
      if (
        props.roundingData.saveRoundingDetailsRes.response.responseCode === 0
      ) {
        toast.success("Charge saved successfully");
        props.roundingData.saveRoundingDetailsRes = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.saveRoundingDetailsRes]);

  const icdSearchFn = (e) => {
    if (e.target.value.length > 2) {
      props.searchIcd({ s: e.target.value, cid: clinicId });
    }
  };

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.searchIcdRes &&
      props.roundingData.searchIcdRes.response
    ) {
      if (props.roundingData.searchIcdRes.response.responseCode === 0) {
        let data = props.roundingData.searchIcdRes.response.data;
        let list = data.map((x) => {
          return {
            title: x.icdCode + " " + x.icdDescLong,
            ...x,
          };
        });
        setIcdSearchList(list);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.searchIcdRes]);

  const handleIcdFormSubmit = () => {
    props.saveIcd({
      ...icdForm,
      clinicId: clinicId,
      editable: true,
      icdDescShort: "",
      icdId: 0,
      icdVersion: 1,
      markAsDelete: 0,
    });
  };

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.saveIcdRes &&
      props.roundingData.saveIcdRes.response
    ) {
      let Response = props.roundingData.saveIcdRes.response;
      if (Response.responseCode === 0) {
        toast.success("ICD saved");
      }
      if (Response.responseCode === 138) {
        toast.error("Clinic ICD Code already exist");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.saveIcdRes.response]);

  const handleSearchCpt = (x) => {
    props.searchCpt({
      cptCode: x,
      clinicId: clinicId,
    });
  };

  const handleSaveCpt = () => {
    props.saveCpt({
      ...newCptValues,
      clinicId: clinicId,
      // cptId: 0,
      favorite: true,
      favoriteCptId: 0,
      frequencyType: 0,
    });
  };

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.saveCptRes &&
      props.roundingData.saveCptRes.response
    ) {
      if (props.roundingData.saveCptRes.response.responseCode === 0) {
        let data = props.roundingData.saveCptRes.response.data;
        toast.success("CPT added");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.saveCptRes.response]);

  const handleFavIcd = () => {
    setOpenFavIcd(true);
    props.favouriteIcdList(providerId);
  };

  const handleFavouriteIcd = (param) => {
    if (param === "dx1") {
      let updatedDx1 = { ...dx1 };
      updatedDx1.favourite = !updatedDx1.favourite;
      setDx1(updatedDx1);
      if (updatedDx1.favourite === true) {
        props.saveFavouriteIcd({
          clinicId: updatedDx1.clinicId,
          favoriteIcdId: 0,
          favouriteIcdType: 0,
          icdCode: updatedDx1.icdCode,
          icdDescLong: updatedDx1.icdDescLong,
          icdDescShort: updatedDx1.icdDescShort,
          icdId: updatedDx1.icdId,
          icdVersion: 0,
          markAsDelete: 0,
          parentId: 0,
          sequenceNo: 0,
          providerId: providerId,
        });
      } else {
        props.deleteFavouriteIcd({
          providerId: providerId,
          icdCode: updatedDx1.icdCode,
        });
      }
    }
    if (param === "dx2") {
      let updatedDx2 = { ...dx2 };
      updatedDx2.favourite = !updatedDx2.favourite;
      setDx2(updatedDx2);
      if (updatedDx2.favourite === true) {
        props.saveFavouriteIcd({
          clinicId: updatedDx2.clinicId,
          favoriteIcdId: 0,
          favouriteIcdType: 0,
          icdCode: updatedDx2.icdCode,
          icdDescLong: updatedDx2.icdDescLong,
          icdDescShort: updatedDx2.icdDescShort,
          icdId: updatedDx2.icdId,
          icdVersion: 0,
          markAsDelete: 0,
          parentId: 0,
          providerId: providerId,
          sequenceNo: 0,
        });
      } else {
        props.deleteFavouriteIcd({
          providerId: providerId,
          icdCode: updatedDx2.icdCode,
        });
      }
    }
    if (param === "dx3") {
      let updatedDx3 = { ...dx3 };
      updatedDx3.favourite = !updatedDx3.favourite;
      setDx3(updatedDx3);
      if (updatedDx3.favourite === true) {
        props.saveFavouriteIcd({
          clinicId: updatedDx3.clinicId,
          favoriteIcdId: 0,
          favouriteIcdType: 0,
          icdCode: updatedDx3.icdCode,
          icdDescLong: updatedDx3.icdDescLong,
          icdDescShort: updatedDx3.icdDescShort,
          icdId: updatedDx3.icdId,
          icdVersion: 0,
          markAsDelete: 0,
          parentId: 0,
          providerId: providerId,
          sequenceNo: 0,
        });
      } else {
        props.deleteFavouriteIcd({
          providerId: providerId,
          icdCode: updatedDx3.icdCode,
        });
      }
    }
    if (param === "dx4") {
      let updatedDx4 = { ...dx4 };
      updatedDx4.favourite = !updatedDx4.favourite;
      setDx4(updatedDx4);
      if (updatedDx4.favourite === true) {
        props.saveFavouriteIcd({
          clinicId: updatedDx4.clinicId,
          favoriteIcdId: 0,
          favouriteIcdType: 0,
          icdCode: updatedDx4.icdCode,
          icdDescLong: updatedDx4.icdDescLong,
          icdDescShort: updatedDx4.icdDescShort,
          icdId: updatedDx4.icdId,
          icdVersion: 0,
          markAsDelete: 0,
          parentId: 0,
          providerId: providerId,
          sequenceNo: 0,
        });
      } else {
        props.deleteFavouriteIcd({
          providerId: providerId,
          icdCode: updatedDx4.icdCode,
        });
      }
    }
  };
  const handleFavCpt = () => {
    setOpenFavCpt(true);
    props.favouriteCptList(providerId);
  };

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.saveFavouriteIcdRes &&
      props.roundingData.saveFavouriteIcdRes.response
    ) {
      if (props.roundingData.saveFavouriteIcdRes.response.responseCode === 0) {
        toast.success("ICD added to favorite list");
        props.roundingData.saveFavouriteIcdRes = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.saveFavouriteIcdRes]);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.deleteFavouriteIcdRes &&
      props.roundingData.deleteFavouriteIcdRes.response
    ) {
      if (
        props.roundingData.deleteFavouriteIcdRes.response.responseCode === 0
      ) {
        toast.success("ICD removed from favorite list");
        props.roundingData.deleteFavouriteIcdRes = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.deleteFavouriteIcdRes]);

  const handleCloseFavIcdList = () => {
    setOpenFavIcd(false);
    // props.getRoundingDetails({
    //   roundingId: details.roundingId,
    //   dos: details.startDate,
    // });
  };

  const handleCloseFavCptList = () => {
    setOpenFavCpt(false);
    // props.getRoundingDetails({
    //   roundingId: details.roundingId,
    //   dos: details.startDate,
    // });
  };

  const addNewRow = () => {
    let cpt = cptList;
    cpt.push({
      amount: 0,
      cashFee: 0,
      clinicId: 0,
      comments: "",
      cptCode: "",
      cptCodeId: 0,
      cptId: 0,
      description: "",
      favorite: false,
      fee: 0,
      icdPointer1: 0,
      icdPointer2: 0,
      icdPointer3: 0,
      icdPointer4: 0,
      icdPointer5: 0,
      icdPointer6: 0,
      icdPointer7: 0,
      icdPointer8: 0,
      id: 0,
      modifier1: "",
      modifier2: "",
      modifier3: "",
      modifier4: "",
      ndcCode: "",
      ndcMeasure: 0,
      ndcUnit: 0,
      pos: "",
      sequenceNum: 0,
      status: 1,
      tos: "",
      unit: 1,
      favoriteCptId: 0,
      dosFrom: visitDetails.dos,
      parentTab: 1,
      dosTo: visitDetails.dos,
      modifier: "",
    });
    setCptList([...cpt]);
  };

  const removeRow = (index) => {
    let cpt = cptList;
    cpt.splice(index, 1);
    setCptList([...cpt]);
  };

  const cptSearchFn = (e, i) => {
    if (e.target.value > 2) {
      props.searchCpt({
        cptCode: e.target.value,
        clinicId: clinicId,
      });
      let form = cptList;
      form[i].cptCode = e.target.value;
      setCptList([...form]);
    }
  };

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.searchCptRes &&
      props.roundingData.searchCptRes.response
    ) {
      if (props.roundingData.searchCptRes.response.responseCode === 0) {
        let data = props.roundingData.searchCptRes.response.data;
        let list = data.map((x) => {
          return {
            title: x.cptCode,
            ...x,
          };
        });
        setSearchCptList(list);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.searchCptRes]);

  const handleChangeCpt = (i, y) => {
    console.log(y);
    if (y) {
      let cpt = cptList;
      cpt[i].cashFee = y.cashFee;
      cpt[i].clinicId = y.clinicId;
      cpt[i].comments = y.comments;
      cpt[i].cptCode = y.cptCode;
      cpt[i].cptId = y.cptId;
      cpt[i].description = y.description;
      cpt[i].fee = y.fee;
      cpt[i].modifier1 = y.modifier;
      cpt[i].ndcCode = y.ndcCode;
      cpt[i].ndcMeasure = y.ndcMeasure;
      cpt[i].ndcUnit = y.ndcUnit;
      cpt[i].pos = y.pos;
      cpt[i].tos = y.tos;
      setCptList([...cpt]);
    }
    if (y === null) {
      let cpt = cptList;
      cpt[i].cashFee = "";
      cpt[i].clinicId = "";
      cpt[i].comments = "";
      cpt[i].cptCode = "";
      cpt[i].cptId = "";
      cpt[i].description = "";
      cpt[i].fee = "";
      cpt[i].modifier1 = "";
      cpt[i].ndcCode = "";
      cpt[i].ndcMeasure = "";
      cpt[i].ndcUnit = "";
      cpt[i].pos = "";
      cpt[i].tos = "";
      setCptList([...cpt]);
    }
  };

  const handleFavouriteCpt = (x, i) => {
    let updatedCpt = [...cptList];
    updatedCpt[i].favorite = !x.favorite;
    setCptList(updatedCpt);
    console.log(updatedCpt[i]);
    if (updatedCpt[i].favorite === true) {
      props.saveFavouriteCpt({
        clinicId: updatedCpt[i].clinicId,
        cptCode: updatedCpt[i].cptCode,
        cptDescLong: updatedCpt[i].description,
        cptDescShort: "",
        cptId: updatedCpt[i].cptId,
        favoriteCptId: 0,
        markAsDelete: 0,
        modifier: "",
        providerId: providerId,
        sequenceNo: 0,
      });
    } else {
      props.deleteFavouriteCpt({
        providerId: providerId,
        cptCode: updatedCpt[i].cptCode,
      });
    }
  };

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.saveFavouriteCptRes &&
      props.roundingData.saveFavouriteCptRes.response
    ) {
      if (props.roundingData.saveFavouriteCptRes.response.responseCode === 0) {
        toast.success("CPT added to favorite list");
        props.roundingData.saveFavouriteCptRes = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.saveFavouriteCptRes]);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.deleteFavouriteCptRes &&
      props.roundingData.deleteFavouriteCptRes.response
    ) {
      if (
        props.roundingData.deleteFavouriteCptRes.response.responseCode === 0
      ) {
        toast.success("CPT removed from favorite list");
        props.roundingData.deleteFavouriteCptRes = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.deleteFavouriteCptRes]);

  //   const dxVariables = [dx1, dx2, dx3, dx4, dx5, dx6, dx7, dx8];
  //   const filteredDxVariables = dxVariables.filter((dx) => dx.icdCode === "");
  // console.log(dxVariables );
  const [favIcdSelectedItems, setFavIcdSelectedItems] = useState([]);
  const handleFavIcdSelectionChange = (selectedItems) => {
    setFavIcdSelectedItems(selectedItems);
    // console.log(selectedItems);
    if (!dx3.icdCode && selectedItems.length > 0) {
      setDx3({
        clinicId: selectedItems[0].clinicId,
        title: selectedItems[0].icdCode,
        icdId: selectedItems[0].icdId,
        icdCode: selectedItems[0].icdCode,
        icdDescShort: selectedItems[0].icdDescShort,
        icdDescLong: selectedItems[0].icdDescLong,
        editable: selectedItems[0].editable,
        rangeId: selectedItems[0].rangeId,
        favourite: true,
      });
    } else {
      setDx3({
        clinicId: visitDetails.visitDiagnosiDto.dx3.clinicId,
        title: visitDetails.visitDiagnosiDto.dx3.icdCode,
        icdId: visitDetails.visitDiagnosiDto.dx3.icdId,
        icdCode: visitDetails.visitDiagnosiDto.dx3.icdCode,
        icdDescShort: visitDetails.visitDiagnosiDto.dx3.icdDescShort,
        icdDescLong: visitDetails.visitDiagnosiDto.dx3.icdDescLong,
        editable: visitDetails.visitDiagnosiDto.dx3.editable,
        rangeId: visitDetails.visitDiagnosiDto.dx3.rangeId,
      });
    }
    if (!dx4.icdCode && selectedItems.length > 1) {
      setDx3({
        clinicId: selectedItems[0].clinicId,
        title: selectedItems[0].icdCode,
        icdId: selectedItems[0].icdId,
        icdCode: selectedItems[0].icdCode,
        icdDescShort: selectedItems[0].icdDescShort,
        icdDescLong: selectedItems[0].icdDescLong,
        editable: selectedItems[0].editable,
        rangeId: selectedItems[0].rangeId,
        favourite: true,
      });
      setDx4({
        clinicId: selectedItems[1].clinicId,
        title: selectedItems[1].icdCode,
        icdId: selectedItems[1].icdId,
        icdCode: selectedItems[1].icdCode,
        icdDescShort: selectedItems[1].icdDescShort,
        icdDescLong: selectedItems[1].icdDescLong,
        editable: selectedItems[1].editable,
        rangeId: selectedItems[1].rangeId,
        favourite: true,
      });
    } else {
      setDx4({
        clinicId: visitDetails.visitDiagnosiDto.dx4.clinicId,
        title: visitDetails.visitDiagnosiDto.dx4.icdCode,
        icdId: visitDetails.visitDiagnosiDto.dx4.icdId,
        icdCode: visitDetails.visitDiagnosiDto.dx4.icdCode,
        icdDescShort: visitDetails.visitDiagnosiDto.dx4.icdDescShort,
        icdDescLong: visitDetails.visitDiagnosiDto.dx4.icdDescLong,
        editable: visitDetails.visitDiagnosiDto.dx4.editable,
        rangeId: visitDetails.visitDiagnosiDto.dx4.rangeId,
      });
    }
  };

  const [favCptSelectedItems, setFavCptSelectedItems] = useState([]);
  const handleFavCptSelectionChange = (selectedItems) => {
    const previouslySelectedItems = favCptSelectedItems.filter((item) =>
      selectedItems.some((selected) => selected.cptCode === item.cptCode)
    );

    // Identify newly selected items
    const newSelectedItems = selectedItems.filter(
      (item) =>
        !favCptSelectedItems.some(
          (selected) => selected.cptCode === item.cptCode
        )
    );

    // Identify unselected items
    const unselectedItems = favCptSelectedItems.filter(
      (item) =>
        !selectedItems.some((selected) => selected.cptCode === item.cptCode)
    );

    // Update the state with the union of previously selected and newly selected items
    setFavCptSelectedItems([...previouslySelectedItems, ...newSelectedItems]);

    // Update the cptList by adding newly selected items and removing unselected items
    setCptList((prevCptList) => {
      const updatedCptList = [
        ...prevCptList.filter(
          (cptItem) =>
            !unselectedItems.some(
              (unselected) => unselected.cptCode === cptItem.cptCode
            )
        ),
        ...newSelectedItems.map((item) => ({
          amount: 0,
          cashFee: 0,
          clinicId: item.clinicId,
          comments: "",
          cptCode: item.cptCode,
          cptCodeId: 0,
          cptId: item.cptId,
          description: item.cptDescLong,
          favorite: true,
          fee: 0,
          icdPointer1: 0,
          icdPointer2: 0,
          icdPointer3: 0,
          icdPointer4: 0,
          icdPointer5: 0,
          icdPointer6: 0,
          icdPointer7: 0,
          icdPointer8: 0,
          id: 0,
          modifier1: "",
          modifier2: "",
          modifier3: "",
          modifier4: "",
          ndcCode: "",
          ndcMeasure: 0,
          ndcUnit: 0,
          pos: "",
          sequenceNum: 0,
          status: 1,
          tos: "",
          unit: 1,
          favoriteCptId: 0,
          dosFrom: visitDetails.dos,
          parentTab: 1,
          dosTo: visitDetails.dos,
          modifier: "",
        })),
      ];
      return updatedCptList;
    });
  };

  return (
    <div className="rounding_main_container">
      <FavIcdDialog
        open={openFavIcd}
        onClose={() => handleCloseFavIcdList()}
        onSelectionChange={handleFavIcdSelectionChange}
      />
      <FavCptDialog
        open={openFavCpt}
        onClose={() => handleCloseFavCptList()}
        onSelectionChange={handleFavCptSelectionChange}
      />

      <Dialog open={openCharges} keepMounted fullWidth maxWidth="lg">
        <div className="new_rounding_modal_container">
          <div
            style={{
              fontSize: "18px",
              fontWeight: "600",
              paddingBottom: "1rem",
              paddingLeft: "15px",
            }}
          >
            Past Charges
          </div>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>DOS</TableCell>
                  <TableCell>Provider</TableCell>
                  <TableCell>CPT</TableCell>
                  <TableCell>ICD1</TableCell>
                  <TableCell>ICD2</TableCell>
                  <TableCell>ICD3</TableCell>
                  <TableCell>ICD4</TableCell>
                  <TableCell>M1</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>08/23/2023</TableCell>
                  <TableCell>Vinit Lal</TableCell>
                  <TableCell>99232</TableCell>
                  <TableCell>E23.1</TableCell>
                  <TableCell>E11.9</TableCell>
                  <TableCell>E11.36</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell
                    style={{ textDecoration: "underline", color: "#14a6a6" }}
                  >
                    Copy
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>08/23/2023</TableCell>
                  <TableCell>Vinit Lal</TableCell>
                  <TableCell>99232</TableCell>
                  <TableCell>E23.1</TableCell>
                  <TableCell>E11.9</TableCell>
                  <TableCell>E11.36</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell
                    style={{ textDecoration: "underline", color: "#14a6a6" }}
                  >
                    Copy
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>08/23/2023</TableCell>
                  <TableCell>Vinit Lal</TableCell>
                  <TableCell>99232</TableCell>
                  <TableCell>E23.1</TableCell>
                  <TableCell>E11.9</TableCell>
                  <TableCell>E11.36</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell
                    style={{ textDecoration: "underline", color: "#14a6a6" }}
                  >
                    Copy
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </Dialog>

      <Dialog open={openEdit}>
        <div className="new_rounding_modal_container" fullWidth maxWidth="lg">
          <div
            style={{
              fontSize: "18px",
              fontWeight: "600",
              paddingBottom: "1.5rem",
            }}
          >
            Edit Rounding
          </div>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                className="rounding_input_item"
                value={selectedPatient}
                variant="outlined"
                label="Patient"
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                className="rounding_input_item"
                value={editRoundingValues.locationId}
                options={locations}
                variant="outlined"
                label="Location"
                onChange={(e) =>
                  setEditRoundingValues({
                    ...editRoundingValues,
                    locationId: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                className="rounding_input_item"
                value={editRoundingValues.providerId}
                options={providers}
                variant="outlined"
                label="Physician"
                onChange={(e) =>
                  setEditRoundingValues({
                    ...editRoundingValues,
                    providerId: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                className="rounding_input_item"
                value={editRoundingValues.refProviderId}
                options={providers}
                variant="outlined"
                label="Referring Physician"
                onChange={(e) =>
                  setEditRoundingValues({
                    ...editRoundingValues,
                    refProviderId: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="number"
                className="rounding_input_item"
                value={editRoundingValues.bed}
                variant="outlined"
                label="Bed"
                onChange={(e) =>
                  setEditRoundingValues({
                    ...editRoundingValues,
                    bed: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Datepicker
                className="rounding_input_item_date"
                value={editRoundingValues.startDate}
                label="Admit Date"
                inputVariant="outlined"
                onChange={(e) =>
                  setEditRoundingValues({
                    ...editRoundingValues,
                    startDate: e,
                  })
                }
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                className="rounding_input_item"
                value={editRoundingValues.notes}
                variant="outlined"
                label="Notes"
                onChange={(e) =>
                  setEditRoundingValues({
                    ...editRoundingValues,
                    notes: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <div style={{ display: "flex", gap: "1rem", paddingTop: "1rem" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  onClick={() => handleEditRounding()}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenEdit(false)}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog open={openNotes} fullWidth>
        <div className="new_rounding_modal_container">
          <div
            style={{
              fontSize: "18px",
              fontWeight: "600",
              paddingBottom: "1rem",
              paddingLeft: "15px",
            }}
          >
            Rounding Notes
          </div>
          <div className="notesTabForm_container">
            <div className="notesTab__input">
              <TextField
                type="text"
                label="Add note"
                // value={newNote}
                // onChange={getNewNote}
                variant="outlined"
              />
            </div>

            <div className="notesTab__btnContainer">
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                // onClick={() => setNewNote("")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                // onClick={handleSubmit}
                // disabled={isSaveClicked}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog open={openRemove} fullWidth>
        <div className="new_rounding_modal_container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "18px",
              fontWeight: "600",
              paddingBottom: "10px",
            }}
          >
            <center>
              Are you sure you want to delete this patient from your rounding
              list?
            </center>
          </div>

          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              style={{ color: "white" }}
              onClick={() => props.deletePatient(details.roundingId)}
            >
              Delete
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setOpenRemove(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog open={openDischarge} fullWidth>
        <div className="new_rounding_modal_container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            <center>
              Are you sure you want to discharge this patient from your rounding
              list?
            </center>
          </div>
          <div
            style={{
              display: "flex",
              gap: "12px",
              justifyContent: "center",
              fontSize: "17px",
            }}
          >
            <div style={{ paddingTop: "1.3rem" }}>
              Select disposition location:{" "}
            </div>
            <div>
              <Dropdown
                value={dischargeDetails.dispositionType}
                options={[
                  { name: "Home", value: "1" },
                  { name: "Rehab/SNF", value: "2" },
                  { name: "No f/u", value: "0" },
                ]}
                onChange={(e) =>
                  setDischargeDetails({
                    ...dischargeDetails,
                    roundingId: selectedRounding,
                    dispositionType: e.target.value,
                    dispositionLocation: e.target.name,
                  })
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              style={{ color: "white" }}
              onClick={() =>
                props.dischargePatient({
                  ...dischargeDetails,
                  roundingId: selectedRounding,
                })
              }
            >
              Discharge
            </Button>
            <Button color="primary" variant="outlined">
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog open={openAddCpt}>
        <div className="new_rounding_modal_container">
          <div
            style={{
              fontSize: "18px",
              fontWeight: "600",
              paddingBottom: "1.5rem",
              // paddingLeft: "15px",
            }}
          >
            {editCpt ? "Edit CPT" : "Add New CPT"}
          </div>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                required
                className="rounding_input_item"
                variant="outlined"
                label="CPT Code"
                value={newCptValues.cptCode}
                onChange={(e) =>
                  setNewCptValues({
                    ...newCptValues,
                    cptCode: e.target.value,
                  })
                }
              />
              {/* <Autocomplete
                fullWidth
                options={searchCptList}
                getOptionLabel={(option) => option.title}
                value={newCptValues.cptCode}
                onChange={(e, x) =>
                  setNewCptValues({ ...newCptValues, cptCode: x })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="CPT Code"
                    variant="outlined"
                    onChange={(e) => handleSearchCpt(e.target.value)}
                  />
                )}
              /> */}
            </Grid>

            <Grid item xs={4}>
              <TextField
                className="rounding_input_item"
                variant="outlined"
                label="Modifier"
                value={newCptValues.modifier}
                onChange={(e) =>
                  setNewCptValues({
                    ...newCptValues,
                    modifier: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                className="rounding_input_item"
                variant="outlined"
                label="NDC Code"
                value={newCptValues.ndcCode}
                onChange={(e) =>
                  setNewCptValues({
                    ...newCptValues,
                    ndcCode: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                className="rounding_input_item"
                variant="outlined"
                label="Description"
                value={newCptValues.description}
                onChange={(e) =>
                  setNewCptValues({
                    ...newCptValues,
                    description: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                type="number"
                className="rounding_input_item"
                variant="outlined"
                label="Fee"
                value={newCptValues.fee}
                onChange={(e) =>
                  setNewCptValues({
                    ...newCptValues,
                    fee: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                type="number"
                className="rounding_input_item"
                variant="outlined"
                label="NDC Units"
                value={newCptValues.ndcUnits}
                onChange={(e) =>
                  setNewCptValues({
                    ...newCptValues,
                    ndcUnits: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                type="number"
                className="rounding_input_item"
                variant="outlined"
                label="Cash Fee"
                value={newCptValues.cashFee}
                onChange={(e) =>
                  setNewCptValues({
                    ...newCptValues,
                    cashFee: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                className="rounding_input_item"
                variant="outlined"
                label="NDC Measure"
                value={newCptValues.ndcMeasure}
                onChange={(e) =>
                  setNewCptValues({
                    ...newCptValues,
                    ndcMeasure: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                className="rounding_input_item"
                variant="outlined"
                label="POS"
                value={newCptValues.pos}
                onChange={(e) =>
                  setNewCptValues({
                    ...newCptValues,
                    pos: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                className="rounding_input_item"
                variant="outlined"
                label="TOS"
                value={newCptValues.tos}
                onChange={(e) =>
                  setNewCptValues({
                    ...newCptValues,
                    tos: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                className="rounding_input_item"
                variant="outlined"
                label="Comments"
                value={newCptValues.comments}
                onChange={(e) =>
                  setNewCptValues({
                    ...newCptValues,
                    comments: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{ color: "white" }}
                    onClick={handleSaveCpt}
                  >
                    Save
                  </Button>
                </div>
                <div>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpenAddCpt(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog open={openAddRounding} keepMounted fullWidth maxWidth="lg">
        <div className="new_rounding_modal_container">
          {addPatient ? (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    paddingBottom: "1.5rem",
                    // paddingLeft: "15px",
                  }}
                >
                  Add New Patient
                </div>
                <div style={{ cursor: "pointer" }}>
                  <CloseIcon
                    onClick={() => setOpenAddRounding(false)}
                    fontSize="small"
                  />
                </div>
              </div>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="First Name"
                    value={patientInfo.firstName}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        firstName: e.target.value,
                      })
                    }
                    onBlur={(e) => validateFirstName(e.target.value)}
                    error={errors.firstName ? true : false}
                    helperText={errors.firstName}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Middle Name"
                    value={patientInfo.middleName}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        middleName: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Last Name"
                    value={patientInfo.lastName}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        lastName: e.target.value,
                      })
                    }
                    onBlur={(e) => validateLastName(e.target.value)}
                    error={errors.lastName ? true : false}
                    helperText={errors.lastName}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Datepicker
                    className="rounding_input_item_date"
                    value={patientInfo.dob}
                    label="DOB"
                    inputVariant="outlined"
                    maxDate={new Date()}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        dob: e,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <PhoneFormat
                    required
                    className="rounding_input_item"
                    variant="outlined"
                    type="text"
                    label="Phone"
                    value={patientInfo.phone}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        phone: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <Dropdown
                    className="rounding_input_item"
                    variant="outlined"
                    label="Gender"
                    options={[
                      { name: "Male", value: 1 },
                      { name: "Female", value: 2 },
                    ]}
                    value={patientInfo.sex}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        sex: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    type="email"
                    className="rounding_input_item"
                    variant="outlined"
                    label="Email"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        email: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="MRN"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        mrn: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Address Line 1"
                    value={patientInfo.addressLine1}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        addressLine1: e.target.value,
                      })
                    }
                    onBlur={(e) => validateAddress1(e.target.value)}
                    error={errors.addressLine1 ? true : false}
                    helperText={errors.addressLine1}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Address Line 2"
                    value={patientInfo.addressLine2}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        addressLine2: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    required
                    className="rounding_input_item"
                    variant="outlined"
                    label="Zip"
                    value={patientInfo.zip}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        zip: e.target.value,
                      })
                    }
                    onBlur={(e) => validateZip(e.target.value)}
                    error={errors.zip ? true : false}
                    helperText={errors.zip}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="City"
                    value={patientInfo.city}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        city: e.target.value,
                      })
                    }
                    onBlur={(e) => validateCity(e.target.value)}
                    error={errors.city ? true : false}
                    helperText={errors.city}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Autocomplete
                    fullWidth
                    options={states}
                    getOptionLabel={(option) => option.name}
                    value={selectedState}
                    onChange={(e, v) => {
                      setPatientInfo({
                        ...patientInfo,
                        state: v.value,
                      });
                      setSelectedState(v);
                    }}
                    // style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State"
                        className="rounding_input_item"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Dropdown
                    className="rounding_input_item"
                    variant="outlined"
                    label="Country"
                    options={[
                      { name: "US", value: "US" },
                      { name: "Mexico", value: "Mex" },
                    ]}
                    value={patientInfo.country}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        country: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <div
                    style={{ display: "flex", gap: "1rem", paddingTop: "1rem" }}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white" }}
                        onClick={handleSavePatient}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setAddPatient(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    paddingBottom: "1rem",
                    // paddingLeft: "15px",
                  }}
                >
                  Patient Rounding Information
                </div>
                <div style={{ cursor: "pointer" }}>
                  <CloseIcon
                    onClick={() => setOpenAddRounding(false)}
                    fontSize="small"
                  />
                </div>
              </div>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Last Name"
                    value={patientInfo.lastName}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        lastName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="First Name"
                    value={patientInfo.firstName}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        firstName: e.target.value,
                      })
                    }
                  />
                </Grid>
                {/* <Grid item xs={3}>
                  <Datepicker
                    className="rounding_input_item_date"
                    value={patientInfo.dob}
                    label="DOB"
                    inputVariant="outlined"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        dob: e,
                      })
                    }
                  />
                </Grid> */}
                <Grid item xs={4}>
                  <div
                    style={{ display: "flex", gap: "1rem", paddingTop: "1rem" }}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white" }}
                        onClick={handleSearchPatient}
                      >
                        Search
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleNewPatient}
                      >
                        New
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}

          {showTable && (
            <Grid xs={12} className="patient_search_table">
              <TableContainer style={{ height: 250 }}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="cell_head" align="left">
                        Last Name
                      </TableCell>
                      <TableCell className="cell_head" align="left">
                        First Name
                      </TableCell>
                      <TableCell className="cell_head" align="left">
                        MRN
                      </TableCell>
                      <TableCell className="cell_head" align="left">
                        DOB
                      </TableCell>
                      <TableCell className="cell_head" align="left">
                        Gender
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {patientList ? (
                    patientList.length !== 0 ? (
                      <TableBody>
                        {patientList.map((x) => (
                          <TableRow
                            style={{ cursor: "pointer" }}
                            onClick={() => fillData(x)}
                          >
                            <TableCell className="cell_info" align="left">
                              {x.lastName}
                            </TableCell>
                            <TableCell className="cell_info" align="left">
                              {x.firstName}
                            </TableCell>
                            <TableCell className="cell_info" align="left">
                              {x.mrn}
                            </TableCell>
                            <TableCell className="cell_info" align="left">
                              {x.sdob}
                            </TableCell>
                            <TableCell className="cell_info" align="left">
                              {x.sex === 1 ? "Male" : "Female"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan="5">
                            No data found
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan="5">
                          No data found
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          )}

          <div
            style={{
              fontSize: "18px",
              fontWeight: "600",
              padding: "2rem 0 1rem",
              // paddingLeft: "15px",
            }}
          >
            Add Rounding Information
          </div>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                className="rounding_input_item"
                value={patientName}
                variant="outlined"
                label="Patient"
                // onChange={(e) =>
                //   setRoundingValues({
                //     ...roundingValues,
                //     patientId: e.target.value,
                //   })
                // }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                className="rounding_input_item"
                value={roundingValues.locationId}
                options={locations}
                variant="outlined"
                label="Location"
                onChange={(e) =>
                  setRoundingValues({
                    ...roundingValues,
                    locationId: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                className="rounding_input_item"
                value={roundingValues.providerId}
                options={providers}
                variant="outlined"
                label="Physician"
                onChange={(e) =>
                  setRoundingValues({
                    ...roundingValues,
                    providerId: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                className="rounding_input_item"
                value={roundingValues.refProviderId}
                options={providers}
                variant="outlined"
                label="Referring Physician"
                onChange={(e) =>
                  setRoundingValues({
                    ...roundingValues,
                    refProviderId: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="number"
                className="rounding_input_item"
                value={roundingValues.bed}
                variant="outlined"
                label="Bed"
                onChange={(e) =>
                  setRoundingValues({
                    ...roundingValues,
                    bed: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Datepicker
                className="rounding_input_item_date"
                value={roundingValues.startDate}
                label="Admit Date"
                inputVariant="outlined"
                onChange={(e) =>
                  setRoundingValues({
                    ...roundingValues,
                    startDate: e,
                  })
                }
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                className="rounding_input_item"
                value={roundingValues.notes}
                variant="outlined"
                label="Notes"
                onChange={(e) =>
                  setRoundingValues({
                    ...roundingValues,
                    notes: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <div style={{ display: "flex", gap: "1rem", padding: "1rem" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  onClick={handleAddRounding}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    setRoundingValues({
                      bed: "",
                      providerId: "",
                      notes: "",
                      roundingId: "",
                      locationId: "",
                      isMedicare: true,
                      clinicId: clinicId,
                      startDate: null,
                      patientId: "",
                    })
                  }
                >
                  Clear
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog open={openIcd.status}>
        <div className="new_rounding_modal_container">
          <form onSubmit={handleIcdFormSubmit}>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "600",
                paddingBottom: "1.5rem",
              }}
            >
              Add/Edit ICD
            </div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  className="input_material_field"
                  required
                  type="text"
                  label="Code"
                  margin="normal"
                  variant="outlined"
                  onChange={(e) =>
                    setIcdForm({ ...icdForm, icdCode: e.target.value })
                  }
                  value={icdForm.icdCode}
                />
              </Grid>
              {/* <Grid item xs={6}></Grid> */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  className="input_material_field"
                  required
                  type="text"
                  label="Description"
                  margin="normal"
                  variant="outlined"
                  onChange={(e) =>
                    setIcdForm({ ...icdForm, icdDescLong: e.target.value })
                  }
                  value={icdForm.icdDescLong}
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                gap: "10px",
                paddingTop: "1rem",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ color: "white" }}
              >
                Save
              </Button>
              <Button
                onClick={() => setopenIcd({ status: false })}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </Dialog>

      {/* <div className="rounding_filter_container">
        <div className="rounding_pagination_container">
          <TablePagination
            component="div"
            rowsPerPageOptions={[50, 100, 200]}
            count={40}
            rowsPerPage={50}
            page={0}
          />
        </div>
        <div className="rounding_filters">
          <div className="rounding_filter_dropdown">
            <Dropdown label="Provider" />
          </div>
          <div className="rounding_filter_dropdown">
            <Dropdown label="Facility" />
          </div>
          <div className="rounding_filter_dropdown">
            <Dropdown
              label="Status"
              value={status}
              options={[
                { name: "Active", value: 1 },
                { name: "Discharged", value: 2 },
              ]}
            />
          </div>
          <div className="rounding_filter_button">
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
            >
              Filter
            </Button>
          </div>
          <div className="rounding_filter_button">
            <Button variant="outlined" color="primary">
              Download
            </Button>
          </div>
        </div>
      </div> */}

      <div className="rounding_main_panel_container">
        <div className="rounding_table_main_container">
          <TableContainer elevation={0} className="rounding_table_container">
            <Table size="small" stickyHeader>
              <TableHead className="rounding_table_head">
                <TableRow>
                  <TableCell className="rounding_table_head_cell">
                    <Checkbox color="primary" size="small" />
                  </TableCell>
                  {headCells.map((x) => (
                    <TableCell className="rounding_table_head_cell" key={x.id}>
                      {x.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length ? (
                  rows.map((x, i) => (
                    <TableRow
                      key={i}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowSelect(x)}
                      className={
                        x.roundingId === selectedRounding
                          ? "rounding_selected_row"
                          : "rounding_row"
                      }
                    >
                      <TableCell className="rounding_table_body_cell">
                        <Checkbox color="primary" size="small" />
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {x.patientMrn}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell patient">
                        {x.patientFirstName + " " + x.patientLastName}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {x.patientDob}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {x.locationName}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {x.patientBed}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {x.los}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {x.isDischarged && <img src={dischargeIcon} />}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {formatDate(x.startDate)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <></>
                )}
              </TableBody>
              <div className="add_new_icon_container">
                <div className="add_new_icon" onClick={handleAddIcon}>
                  <AddRoundedIcon style={{ color: "#fff", fontSize: "2rem" }} />
                </div>
              </div>
            </Table>
          </TableContainer>
        </div>
        <div className="rounding_second_panel">
          <div className="rounding_second_panel_card">
            <div className="rounding_second_panel_card_first_item">
              <div className="rounding_second_panel_card_item_row">
                <div style={{ display: "flex", gap: "10px" }}>
                  <div className="rounding_second_panel_card_heading">
                    {details.patientFirstName
                      ? details.patientFirstName + " " + details.patientLastName
                      : ""}
                  </div>
                  <div className="rounding_action_link">Edit</div>
                </div>
                <div style={{ fontWeight: "500" }}>
                  {details.locationName ? details.locationName : ""}
                </div>
              </div>
              <div className="rounding_second_panel_card_item_row">
                <div style={{ fontSize: "14px", color: "#828282" }}>
                  {details.patientDob}
                </div>
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#828282",
                  }}
                >
                  DOS: {details.startDate ? formatDate(details.startDate) : ""}
                </div>
              </div>
            </div>

            <div
              className="icd_container"
              style={{ paddingTop: "0.8rem", paddingBottom: "0.5rem" }}
            >
              <div className="rounding_second_panel_card_item">
                <div
                  style={{ paddingBottom: "4px" }}
                  className="rounding_second_panel_card_heading"
                >
                  ICD
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div className="rounding_action_link">Search</div>
                  <div className="rounding_action_link" onClick={handleFavIcd}>
                    Favorites
                  </div>
                  <div>
                    <MenuRoundedIcon
                      fontSize="small"
                      style={{ color: "#828282", paddingTop: "3px" }}
                    />
                  </div>
                </div>
              </div>

              <div className="icd_search_items_container">
                <div className="icd_search_item">
                  <Autocomplete
                    fullWidth
                    options={icdSearchList}
                    onChange={(e, x) => handleIcd("dx1", x)}
                    value={dx1}
                    closeIcon={<div></div>}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ICD1"
                        onChange={icdSearchFn}
                      />
                    )}
                  />
                  <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                    onClick={() => setopenIcd({ status: true, key: "DX1" })}
                  />
                  {dx1 ? (
                    dx1.favourite === true && dx1.icdCode ? (
                      <StarIcon
                        color="primary"
                        fontSize="small"
                        className="icd_search_icon"
                        onClick={() => handleFavouriteIcd("dx1")}
                      />
                    ) : (
                      <StarBorderIcon
                        color="primary"
                        fontSize="small"
                        className="icd_search_icon"
                        onClick={() => handleFavouriteIcd("dx1")}
                      />
                    )
                  ) : (
                    <StarBorderIcon
                      color="primary"
                      fontSize="small"
                      className="icd_search_icon"
                      onClick={() => handleFavouriteIcd("dx1")}
                    />
                  )}
                  <CloseIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                    onClick={() =>
                      setDx1({
                        clinicId: "",
                        favourite: "",
                        icdCode: "",
                        icdDescLong: "",
                        icdDescShort: "",
                        icdId: "",
                        rangeId: "",
                      })
                    }
                  />
                </div>

                <div className="icd_search_item">
                  <Autocomplete
                    fullWidth
                    options={icdSearchList}
                    onChange={(e, x) => handleIcd("dx2", x)}
                    value={dx2}
                    closeIcon={<div></div>}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ICD2"
                        onChange={icdSearchFn}
                      />
                    )}
                  />
                  <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                    onClick={() => setopenIcd({ status: true, key: "DX2" })}
                  />
                  {dx2 ? (
                    dx2.favourite === true && dx2.icdCode ? (
                      <StarIcon
                        color="primary"
                        fontSize="small"
                        className="icd_search_icon"
                        onClick={() => handleFavouriteIcd("dx2")}
                      />
                    ) : (
                      <StarBorderIcon
                        color="primary"
                        fontSize="small"
                        className="icd_search_icon"
                        onClick={() => handleFavouriteIcd("dx2")}
                      />
                    )
                  ) : (
                    <StarBorderIcon
                      color="primary"
                      fontSize="small"
                      className="icd_search_icon"
                      onClick={() => handleFavouriteIcd("dx2")}
                    />
                  )}
                  <CloseIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                    onClick={() =>
                      setDx2({
                        clinicId: "",
                        favourite: "",
                        icdCode: "",
                        icdDescLong: "",
                        icdDescShort: "",
                        icdId: "",
                        rangeId: "",
                      })
                    }
                  />
                </div>

                <div className="icd_search_item">
                  <Autocomplete
                    fullWidth
                    options={icdSearchList}
                    onChange={(e, x) => handleIcd("dx3", x)}
                    value={dx3}
                    closeIcon={<div></div>}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ICD3"
                        onChange={icdSearchFn}
                      />
                    )}
                  />
                  <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                    onClick={() => setopenIcd({ status: true, key: "DX3" })}
                  />
                  {dx3 ? (
                    dx3.favourite === true && dx3.icdCode ? (
                      <StarIcon
                        color="primary"
                        fontSize="small"
                        className="icd_search_icon"
                        onClick={() => handleFavouriteIcd("dx3")}
                      />
                    ) : (
                      <StarBorderIcon
                        color="primary"
                        fontSize="small"
                        className="icd_search_icon"
                        onClick={() => handleFavouriteIcd("dx3")}
                      />
                    )
                  ) : (
                    <StarBorderIcon
                      color="primary"
                      fontSize="small"
                      className="icd_search_icon"
                      onClick={() => handleFavouriteIcd("dx3")}
                    />
                  )}
                  <CloseIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                    onClick={() =>
                      setDx3({
                        clinicId: "",
                        favourite: "",
                        icdCode: "",
                        icdDescLong: "",
                        icdDescShort: "",
                        icdId: "",
                        rangeId: "",
                      })
                    }
                  />
                </div>

                <div className="icd_search_item">
                  <Autocomplete
                    fullWidth
                    options={icdSearchList}
                    onChange={(e, x) => handleIcd("dx4", x)}
                    value={dx4}
                    closeIcon={<div></div>}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ICD4"
                        onChange={icdSearchFn}
                      />
                    )}
                  />
                  <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                    onClick={() => setopenIcd({ status: true, key: "DX4" })}
                  />
                  {dx4 ? (
                    dx4.favourite === true && dx4.icdCode ? (
                      <StarIcon
                        color="primary"
                        fontSize="small"
                        className="icd_search_icon"
                        onClick={() => handleFavouriteIcd("dx4")}
                      />
                    ) : (
                      <StarBorderIcon
                        color="primary"
                        fontSize="small"
                        className="icd_search_icon"
                        onClick={() => handleFavouriteIcd("dx4")}
                      />
                    )
                  ) : (
                    <StarBorderIcon
                      color="primary"
                      fontSize="small"
                      className="icd_search_icon"
                      onClick={() => handleFavouriteIcd("dx4")}
                    />
                  )}
                  <CloseIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                    onClick={() =>
                      setDx4({
                        clinicId: "",
                        favourite: "",
                        icdCode: "",
                        icdDescLong: "",
                        icdDescShort: "",
                        icdId: "",
                        rangeId: "",
                      })
                    }
                  />
                </div>
                {/* <div>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    style={{ color: "white" }}
                  >
                    Save
                  </Button>
                </div> */}
              </div>
            </div>

            <div className="cpt_container">
              <div className="rounding_second_panel_card_item">
                <div style={{ display: "flex", gap: "50px" }}>
                  <div
                    style={{ paddingBottom: "4px" }}
                    className="rounding_second_panel_card_heading"
                  >
                    CPT
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      className={ip ? "selected_ip_op" : "cpt_ip_op"}
                      onClick={() => setIp(true)}
                    >
                      I/P
                    </div>
                    <div
                      className={ip ? "cpt_ip_op" : "selected_ip_op"}
                      onClick={() => setIp(false)}
                    >
                      O/P
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", gap: "10px" }}>
                  <div className="rounding_action_link" onClick={handleFavCpt}>
                    Favorites
                  </div>
                  <div>
                    <MenuRoundedIcon
                      fontSize="small"
                      style={{
                        color: "#828282",
                        paddingTop: "3px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div style={{ paddingBottom: "0.5rem" }}>
                <div style={{ paddingBottom: "0.8rem" }}>
                  <Tabs
                    value={cptTab}
                    indicatorColor="primary"
                    textColor="primary"
                    className="cpt_tabs_header"
                    onChange={handleCptTab}
                  >
                    <Tab label="New" {...a11yProps(0)}></Tab>
                    <Tab label="Sub" {...a11yProps(1)}></Tab>
                    <Tab label="Consult" {...a11yProps(2)}></Tab>
                    <Tab label="ICU" {...a11yProps(3)}></Tab>
                    <Tab label="Dis" {...a11yProps(4)}></Tab>
                  </Tabs>
                  <TabPanel value={cptTab} index={0}>
                    <>
                      <div className="cpt_chips_container">
                        {cptList.length
                          ? cptList.map((x) => (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                  paddingBottom: "1rem",
                                  paddingTop: "1rem",
                                }}
                              >
                                <Chip
                                  className="cpt_chip"
                                  label={x.cptCode}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                />
                              </div>
                            ))
                          : ""}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexWrap: "wrap",
                        }}
                      >
                        {cptList.length ? (
                          cptList.map((x, i) => (
                            <div className="cpt_card_container">
                              <div className="cpt_card_row1">
                                <div className="cpt_code">
                                  <div style={{ width: "110px" }}>
                                    <Autocomplete
                                      options={searchCptList}
                                      getOptionLabel={(option) => option.title}
                                      value={{ title: cptList[i].cptCode }}
                                      onChange={(e, y) => handleChangeCpt(i, y)}
                                      closeIcon={<div></div>}
                                      renderInput={(params) => (
                                        <TextField
                                          className="cpt_text_field"
                                          {...params}
                                          onChange={(e) => cptSearchFn(e, i)}
                                        />
                                      )}
                                    />
                                    {x.cptId ? (
                                      <EditRounded
                                        className="cpt_icons"
                                        style={{ color: "#828282" }}
                                        fontSize="small"
                                        onClick={() => {
                                          console.log("Edit button clicked");
                                          setOpenAddCpt(true);
                                          setEditCpt(true);
                                          setNewCptValues({
                                            cashFee: x.cashFee,
                                            clinicId: clinicId,
                                            comments: x.comments,
                                            cptCode: x.cptCode,
                                            cptId: x.cptId,
                                            description: x.description,
                                            favorite: x.favorite,
                                            fee: x.fee,
                                            modifier: x.modifier1,
                                            ndcCode: x.ndcCode,
                                            ndcMeasure: x.ndcMeasure,
                                            ndcUnits: x.ndcUnits,
                                            pos: x.pos,
                                            tos: x.tos,
                                            unit: x.unit,
                                          });
                                        }}
                                      />
                                    ) : (
                                      <AddCircleOutlineIcon
                                        color="primary"
                                        className="cpt_icons"
                                        fontSize="small"
                                        onClick={() => {
                                          setOpenAddCpt(true);
                                          setEditCpt(false);
                                          setNewCptValues(defaultCptValues);
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    marginRight: "25px",
                                  }}
                                >
                                  <div className="cpt_modifier">U</div>
                                  <div className="cpt_modifier">M1</div>
                                  <div className="cpt_modifier">M2</div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    gap: "2px",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ cursor: "pointer" }}>
                                    {x.favorite === true ? (
                                      <StarIcon
                                        fontSize="small"
                                        color="primary"
                                        onClick={() => handleFavouriteCpt(x, i)}
                                      />
                                    ) : (
                                      <StarBorderIcon
                                        fontSize="small"
                                        color="primary"
                                        onClick={() => handleFavouriteCpt(x, i)}
                                      />
                                    )}
                                  </div>
                                  <div style={{ cursor: "pointer" }}>
                                    <CloseIcon
                                      color="primary"
                                      fontSize="small"
                                      onClick={() => removeRow(i)}
                                    />
                                  </div>
                                  {cptList.length === i + 1 && (
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => addNewRow()}
                                    >
                                      <AddCircleOutlineIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="cpt_card_row2">
                                <div className="cpt_detail">
                                  {x.description}
                                </div>
                                <div className="cpt_detail">
                                  Allowed: ${x.amount}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            style={{ cursor: "pointer", paddingTop: "1rem" }}
                          >
                            <Button
                              size="small"
                              color="primary"
                              variant="contained"
                              style={{ color: "white" }}
                              onClick={() => {
                                setOpenAddCpt(true);
                                setEditCpt(false);
                                setNewCptValues(defaultCptValues);
                              }}
                            >
                              Add CPT
                            </Button>
                          </div>
                        )}
                      </div>
                    </>
                  </TabPanel>
                  <TabPanel value={cptTab} index={1}>
                    Sub
                  </TabPanel>
                  <TabPanel value={cptTab} index={2}>
                    Consult
                  </TabPanel>
                  <TabPanel value={cptTab} index={3}>
                    ICU
                  </TabPanel>
                  <TabPanel value={cptTab} index={4}>
                    Dis
                  </TabPanel>
                </div>
              </div>
            </div>

            <div className="note_container">
              <div className="rounding_second_panel_card_item_note">
                <div className="rounding_second_panel_card_heading">
                  Notes to biller
                </div>
                <div className="rounding_action_link">View</div>
              </div>
              <div className="notes_input_container">
                <div>
                  <TextField
                    size="small"
                    multiline
                    variant="outlined"
                    placeholder="Enter any notes for biller"
                    value={billerNote}
                    onChange={(e) => setBillerNote(e.target.value)}
                    className="note_text_field"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    maxHeight: "32px",
                  }}
                >
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    style={{ color: "#ffff" }}
                  >
                    Submit
                  </Button>
                  <Button size="small" color="primary" variant="outlined">
                    Clear
                  </Button>
                </div>
              </div>
            </div>

            <div className="note_container">
              <div className="rounding_second_panel_card_item_note">
                <div className="rounding_second_panel_card_heading">
                  Rounding notes
                </div>
                <div className="rounding_action_link">View</div>
              </div>
              <div className="notes_input_container">
                <div>
                  <TextField
                    size="small"
                    multiline
                    variant="outlined"
                    placeholder="Enter rounding notes"
                    value={roundingNote}
                    onChange={(e) => setRoundingNote(e.target.value)}
                    className="note_text_field"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "flex-end",
                    maxHeight: "32px",
                  }}
                >
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    style={{ color: "#ffff" }}
                  >
                    Submit
                  </Button>
                  <Button size="small" color="primary" variant="outlined">
                    Clear
                  </Button>
                </div>
              </div>
            </div>

            <div className="actions_container">
              <div className="rounding_second_panel_card_item">
                <div className="rounding_second_panel_card_heading">
                  Actions
                </div>
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <Button
                  size="small"
                  style={{ color: "#828282", borderColor: "#828282" }}
                  variant="outlined"
                  onClick={() => setOpenEdit(true)}
                >
                  Edit
                </Button>
                <Button
                  size="small"
                  style={{ color: "#E39600", borderColor: "#E39600" }}
                  variant="outlined"
                  onClick={() => setOpenDischarge(true)}
                >
                  Discharge
                </Button>
                <Button
                  size="small"
                  style={{ color: "#4F566B", borderColor: "#4F566B" }}
                  variant="outlined"
                  onClick={() => setOpenRemove(true)}
                >
                  Delete
                </Button>
                <Button
                  size="small"
                  color="primary"
                  style={{ color: "#ffff" }}
                  variant="contained"
                  onClick={() => handleSaveRoundingDetails()}
                >
                  Save changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  roundingData: state.rounding,
});

const mapDispatchToProps = (dispatch) => ({
  getRoundingList: (values) => dispatch(getRoundingList(values)),
  addRounding: (values) => dispatch(addRounding(values)),
  editRounding: (values) => dispatch(editRounding(values)),
  getRoundingDetails: (values) => dispatch(getRoundingDetails(values)),
  saveRoundingDetails: (values) => dispatch(saveRoundingDetails(values)),
  searchPatient: (values) => dispatch(searchPatient(values)),
  savePatient: (values) => dispatch(savePatient(values)),
  deletePatient: (values) => dispatch(deletePatient(values)),
  dischargePatient: (values) => dispatch(dischargePatient(values)),
  searchIcd: (values) => dispatch(searchIcd(values)),
  saveIcd: (values) => dispatch(saveIcd(values)),
  searchCpt: (values) => dispatch(searchCpt(values)),
  saveCpt: (values) => dispatch(saveCpt(values)),
  favouriteIcdList: (values) => dispatch(favouriteIcdList(values)),
  favouriteCptList: (values) => dispatch(favouriteCptList(values)),
  saveFavouriteIcd: (values) => dispatch(saveFavouriteIcd(values)),
  deleteFavouriteIcd: (values) => dispatch(deleteFavouriteIcd(values)),
  saveFavouriteCpt: (values) => dispatch(saveFavouriteCpt(values)),
  deleteFavouriteCpt: (values) => dispatch(deleteFavouriteCpt(values)),
});

Rounding.propTypes = {
  roundingData: PropTypes.object,
  getRoundingList: PropTypes.func,
  addRounding: PropTypes.func,
  editRounding: PropTypes.func,
  getRoundingDetails: PropTypes.func,
  saveRoundingDetails: PropTypes.func,
  searchPatient: PropTypes.func,
  savePatient: PropTypes.func,
  deletePatient: PropTypes.func,
  dischargePatient: PropTypes.func,
  searchIcd: PropTypes.func,
  saveIcd: PropTypes.func,
  searchCpt: PropTypes.func,
  saveCpt: PropTypes.func,
  favouriteIcdList: PropTypes.func,
  favouriteCptList: PropTypes.func,
  saveFavouriteIcd: PropTypes.func,
  deleteFavouriteIcd: PropTypes.func,
  saveFavouriteCpt: PropTypes.func,
  deleteFavouriteCpt: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Rounding);
